<template>
  <div>{{ t('Dahsboard') }}</div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'

export default {
  setup() {
    const { t } = useUtils()
    return { t }
  },
}
</script>

<style>

</style>
